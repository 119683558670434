import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import PreviewCompatibleImage from "./PreviewCompatibleImage";

const AuthorPhoto = ({ name }) => {
  const data = useStaticQuery(graphql`
    query AuthorQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "author" } } }
      ) {
        nodes {
          frontmatter {
            title
            logo {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const imageData = data.allMarkdownRemark.nodes.find(
    (row) => row.frontmatter.title === name
  );

  return (
    <div className="author-photo">
      <PreviewCompatibleImage
        imageInfo={{
          image: imageData.frontmatter.logo,
          alt: imageData.frontmatter.title,
          width:
            imageData.frontmatter.logo.childImageSharp.gatsbyImageData.width,
          height:
            imageData.frontmatter.logo.childImageSharp.gatsbyImageData.height,
        }}
      />
    </div>
  );
};

export default AuthorPhoto;
