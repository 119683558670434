import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarDays } from '@fortawesome/free-regular-svg-icons';

import AuthorPhoto from '../components/AuthorPhoto';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import HomeCta from '../components/HomeCta';
import Tag from '../components/Tag';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import readingTime from '../utils/readingTime';

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  tags,
  title,
  createdAt,
  readTime = 2,
  authorName,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <article id="top" className="section">
      {helmet || ''}
      <div className="container content container-page">
        <div className="columns">
          <div className="column">
            <div className="is-10">
              <div className="categories">
                {tags &&
                  tags.map((tag, index) => <Tag key={`tag-${index}`} variant="blue" label={tag} />)}
              </div>
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">{title}</h1>
            </div>
            <div className="article-info">
              <div>
                <FontAwesomeIcon icon={faCalendarDays} />
                <span>{createdAt}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faClock} />
                <span>{`${readTime} min read`}</span>
              </div>
              <div>
                {authorName && <AuthorPhoto name={authorName} />}
                <span>{`by ${!authorName ? 'AuditCover' : authorName}`}</span>
              </div>
            </div>
            <div className="is-10">
              <PostContent content={content} />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout noPadding isWhite={true} darkLogo={true}>
      {post.frontmatter.featuredimage && (
        <div className="featured-image">
          <PreviewCompatibleImage
            imageInfo={{
              image: post.frontmatter.featuredimage,
              alt: post.frontmatter.title,
              width: post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.width,
              height: post.frontmatter.featuredimage.childImageSharp.gatsbyImageData.height,
            }}
          />
        </div>
      )}
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        readTime={readingTime(post.html)}
        createdAt={post.frontmatter.date}
        authorName={post.frontmatter.author}
      />
      {/* <ContinueReading /> */}
      <HomeCta />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      timeToRead
      frontmatter {
        tags
        title
        date(formatString: "MMMM Do YYYY")
        description
        featuredimage {
          childImageSharp {
            gatsbyImageData
          }
        }
        author
        logo {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
